/* VERSION DESIGN - Non Material UI Styles for VALIDO APP build */

/* General styles
---------------------------------------------------------------------------------------------- */

/* Colour Pallet
----------------------------------------------------------------------------------------------
#EC6564 - Pink
#1c92ad - Green/Blue
#2A558C - Dark Blue
#76CEF8 - Light Blue
#94AECB - Flat Blue
#4B4B4B - Grey BG
#697071 - Grey Text

/* Font Sizes - converted from px to rem
----------------------------------------------------------------------------------------------
8px		- 	0.5rem
9px		- 	0.563rem
10px		- 	0.625rem
11px		- 	0.688rem
12px		- 	0.75rem
13px		-	0.813rem
14px		-	0.875rem
15px		-	0.938rem
16px		-	1rem
17px		-	1.063rem
18px		-	1.125rem
19px		-	1.188rem
20px		-	1.25rem
---------------------------------------------------------------------------------------------- */

/* Font weights
----------------------------------------------------------------------------------------------
200 - Extra-light
300 - Light
400 - Regular
600 - Semi-Bold
700 - Bold
800 - Extra-bold
900 - Black
---------------------------------------------------------------------------------------------- */


/* Base Styles */

/*

* {
	margin: 0;
	padding: 0;
	outline: 0;
}

*/

body {
	min-height: 667px;
	margin-bottom: 70px;
	clear: both;
	background-color: #ffffff;
}


body {
	font-family: 'Nunito Sans', sans-serif !important;
	font-weight: normal;
	font-style: normal;
}

body, h1, h2, h3, h4, h5, h6,  p, ul, li, form {
	margin: 0px;
	padding: 0px;
}

p {
	font-size: 0.875rem;
	line-height: 1.2rem;
}

input, select, button {
	font-family: 'Nunito Sans', sans-serif;
	font-weight: normal;
	font-style: normal;
}

input, select, button {
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */
}

a:link, a:visited {
	outline: none;
	color: #5cadff;
	text-decoration: none;
}

a:hover, a:active {
	color: #76CEF8;
	text-decoration: none;
	outline: none;
}

img {
	border: none;
}

#wrapper {
	margin: 0 auto;
}

#main {
	width: 100%;
	min-height: auto;
	margin: 0 auto;
	padding: 0 0 100px 0;
	overflow:hidden;
	position:relative;
	margin-top: 110px;
	overflow: visible;
	max-width: 1280px;
}

#main.large-header {
	margin-top: 250px;
}

#main.blue-bg {
	margin-top: 0;
	background-color: #94aecb;
}

#main.grey-bg {
	margin-top: 0;
	background-color: #4b4b4b;
}

#main.white-bg {
	margin-top: 0;
	background-color: #ffffff;
}

#main.no-top-margin {
	margin-top: 92px;
}

#main.fixed-overlay {
	position: fixed;
	top: 0;
	height: calc(100vh - 120px);
}

.fullscreenBlueBg {
	margin-top: 0;
	background-color: #94aecb;
}

img {
	width: 100%;
	height: auto;
}

/* Material UI Component overrides 
---------------------------------------------------------------------------------------------- */

.makeStyles-entryButton-2:hover,
.makeStyles-entryButton-2:active,
.makeStyles-entryButton-2:focus {
	/* background-color: #9aafc1 !important;
	color: white !important; */
}

label.nvpLabel {
	color: #9e9d9d; /* #607c96; */ /* OVERRIDE WORKS */
	font-family: 'Nunito Sans', sans-serif;
	top: -2px !important;
}

.MuiTextField-root fieldset {
	border-color: rgba(134,132,132,0.4) !important; /* OVERRIDE WORKS */
}

.nvpInput {
	font-weight: 400 !important;	
	color: #383838 !important; /* OVERRIDE WORKS */
	font-family: 'Nunito Sans', sans-serif !important;
	padding: 13px 14px !important;
	
}

.nvpVcodeInput {
	font-weight: 700 !important;	
	color: #9e9d9d !important;
	font-family: 'Nunito Sans', sans-serif !important;
	font-size: 1.25rem !important;
	text-align: center;
	padding-top: 17px !important;
	padding-bottom: 17px !important;
}

.nvpVcodeInput fieldset {
	border-color: #E3E3E3;
	border-width: 2px;
	background-color: white;
}

label.nvpVcodeLabel {
	font-weight: 700;	
	color: #9e9d9d;
	font-family: 'Nunito Sans', sans-serif;
	font-size: 1.25rem;
	text-align: center;
	width: calc(100% - 28px);
}

.MuiFormControl-marginDense {
	margin-bottom: 0 !important;
}

.savedcardInput,
.savedcardInput fieldset {
	background-color: black !important;
	background-image: url('assets/svg/visa-card-icon-lrg.svg') !important;
	background-repeat: no-repeat !important;
	background-position: left center !important;
	background-size: 40px !important;
	border-radius: 7px;
	color: white !important;
	text-indent: 40px;
	font-family: 'Nunito Sans', sans-serif !important;
}

label.nvpLabelwhite {
	color: #ffffff !important;
	font-family: 'Nunito Sans', sans-serif !important;
	text-indent: 40px;
}

.entryButtonLarge {
	text-transform: none !important;
	font-family: 'Nunito Sans', sans-serif !important;
	font-size: 2.125rem !important;
	letter-spacing: 0 !important;
	padding-top: 70px;
	padding-bottom: 70px;
}

.entryButtonMedium {
	text-transform: none !important;
	font-family: 'Nunito Sans', sans-serif !important;
	font-size: 1.063rem !important;
	letter-spacing: 0 !important;
	padding-top: 70px !important;
	padding-bottom: 15px !important;
}

.entryButtonMedium.view-carparks {
	background-image: url('assets/svg/entry-button-icon-view-carparks.svg');
	background-repeat: no-repeat;
	background-position: center 20px;
	background-size: 40px !important;
}

.entryButtonMedium.view-merchants {
	background-image: url('assets/svg/entry-button-icon-view-merchants.svg');
	background-repeat: no-repeat;
	background-position: center 20px;
	background-size: 38px !important;
}

.entryButtonSmall {
	text-transform: none !important;
	font-family: 'Nunito Sans', sans-serif !important;
	font-size: 1rem !important;
	letter-spacing: 0 !important;
	padding-top: 40px !important;
	padding-bottom: 10px !important;
}

.entryButtonSmall.record-purchase {
	background-image: url('assets/svg/entry-button-icon-record-purchase.svg');
	background-repeat: no-repeat;
	background-position: center 10px;
	background-size: 28px !important;
}

.entryButtonSmall.claim-parking {
	background-image: url('assets/svg/entry-button-icon-claim-parking.svg');
	background-repeat: no-repeat;
	background-position: center 15px;
	background-size: 54px !important;
}

.validoSelect {
	background-color: white;
}

.validoSelect .MuiSelect-select {
	font-family: 'Nunito Sans', sans-serif !important;
	padding-top: 13px;
	padding-bottom: 13px;
	color: #868484;
}


/* Header
---------------------------------------------------------------------------------------------- */

#header-wrapper {
	width: 100%;
	margin: 0 auto;
	padding: 0;
	height: auto;
	position: fixed;
	top: 0;
	z-index: 9999;
	background-color: #2a558c;
}

#header-wrapper.white-header,
#header-wrapper.landing-page-header {
	background-color: white;	
}

#header-wrapper.no-page-title {
	background-color: #4b4b4b;	
}

#header-wrapper.landing-page-header {
	background-image: url('assets/images/valido-header-ovelay-img.png');
	background-repeat: no-repeat;
	background-position: top left;
	background-size: 350px;
	position: relative;
	height: 280px;
}

#header-wrapper.image-header {
	max-height: 250px;
	height: 250px;
	background-color: white;
}

#header {
	margin: 0 auto;
	padding: 0 16px;
	height: auto;
	min-height: 90px;
	/* max-width: 1280px; */
	color: white;
}

#header-wrapper.landing-page-header #header {
	border-top: 7px solid black;
}

#header-wrapper.image-header #header {
	background-color: #2a558c;
	max-height: 90px;
}

.header-image {
	display: block;
	height: 250px;
	width: 100%;
	background-image: url('assets/images/valido-header-ovelay-img.png');
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	top: 0;
	z-index: 9999999;
}

a#valido-logo {
	display: block;
	float: right;
	width: 104px;
	height: 49px;
	background-image: url('assets/svg/valido-logo.svg');
	background-repeat: no-repeat;
	background-size: 104px 49px;
	margin-top: 20px;
}

a#nuevopark-logo {
	display: block;
	float: right;
	width: 195px;
	height: 46px;
	background-image: url('assets/svg/nuevopark-logo.svg');
	background-repeat: no-repeat;
	background-size: 195px 46px;
	margin-top: 22px;
}

a#valido-logo.landing-page {
	margin-top: 12px;
}

a#valido-icon {
	display: block;
	float: right;
	width: 35px;
	height: 30px;
	background-image: url('assets/svg/valido-header-icon.svg');
	background-repeat: no-repeat;
	background-size: 35px 30px;
	margin-top: 15px;
}

a#search-activity {
	display: block;
	float: right;
	width: 18px;
	height: 18px;
	background-image: url('assets/svg/header-icon-mag-glass.svg');
	background-repeat: no-repeat;
	background-size: 18px 18px;
	background-position: left center;
	margin-top: 30px;
	padding-right: 12px;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-right: 10px;
	border-right: 1px solid white;
}

a#list-activity {
	display: block;
	float: right;
	width: 18px;
	height: 18px;
	background-image: url('assets/svg/header-icon-options.svg');
	background-repeat: no-repeat;
	background-size: 18px 18px;
	background-position: left center;
	margin-top: 30px;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-right: 10px;
}
	

#header-wrapper.white-header a#valido-logo,
#header-wrapper.landing-page-header a#valido-logo { 
	background-image: url('assets/svg/valido-home-logo-blue.svg');
}

#header h1 {
	font-size: 1.5rem;
	line-height: 50px;
	font-weight: 400;
	float: left;
	margin-top: 14px;
	padding-left: 35px;
	background-size: 25px 25px;
	background-repeat: no-repeat;
	background-position: left center;
}

#header h1.parker-id {
	font-size: 1.25rem;
	line-height: 1.7rem;
	font-weight: 400;
	float: left;
	margin-top: 17px;
	padding-left: 0px;
}

#header h1.parker-id .subtitle {
	font-size: 1.125rem;
}

#header h1.sign-up {
	background-image: url('assets/svg/h1-icon-sign-up.svg');
	background-position: left 20px;
}

#header h1.sign-in {
	background-image: url('assets/svg/h1-icon-sign-in.svg');
	background-position: left 20px;
}

#header h1.my-activity {
	background-image: url('assets/svg/h1-icon-my-activity.svg');
	background-position: left 20px;
}

#header h1.processing-payment,
#header h1.payment-successful {
	background-image: url('assets/svg/h1-icon-processing.svg');
	background-size: 25px;
	background-position: left 18px;
}

#header h1.licence-plate {
	background-image: url('assets/svg/h1-icon-car.svg');
	background-size: 29px;
	background-position: left 20px;
}

#header h1.entry-method {
	background-image: url('assets/svg/h1-icon-entry-method.svg');
	background-size: 32px;
	background-position: left 15px;
}

#header h1.forget-me {
	background-image: url('assets/svg/h1-icon-forget-me.svg');
	background-size: 24px;
	background-position: left 19px;
}

#header h1.my-parks {
	background-image: url('assets/svg/h1-icon-my-parks.svg');
	background-size: 20px;
	background-position: left 19px;
}

#header h1.my-cards {
	background-image: url('assets/svg/h1-icon-my-cards.svg');
	background-size: 29px;
	background-position: left 19px;
}

#header h1.my-vehicles {
	background-image: url('assets/svg/h1-icon-my-vehicles.svg');
	background-size: 26px;
	background-position: left 22px;
}

#header-wrapper.landing-page-header #header .main-header-image {
	float: left;
	max-height: 250px;
	width: auto;
}

#header .header-h2-wrapper {
	float: right;
}

h2.entryScreen {
	text-align: right;
	color: #1c92ad;
	font-weight: 300;
	font-size: 1.5rem;
	line-height: 1.7rem;
	display: block;
	padding-top: 42px;
	letter-spacing: -0.03rem;
	clear: both;
}

h2.entryScreen strong {
	font-weight: 800;
}

.header-button-wrapper {
	display: block;
	margin-top: 55px;
	clear: both;
}

.header-button-wrapper p {
	font-weight: 600;
	font-size: 1rem;
	line-height: 1rem;
}

.header-button {
	display: block;
	color: #5cadff;
	text-align: right;
}

.header-button::after {
	display: inline-flex;
	content: '';
	background-image: url("assets/svg/small-arrow-blue-r.svg");
	background-size: 8px 10px;
	background-repeat: no-repeat;
	background-position: right center;
	height: 10px;
	width: 17px;
	margin-right: 5px;
}

.slider-image {
	max-height: 215px;
	width: auto;
	padding-top: 7px;
}

p.slider-text {
	font-size: 0.938rem;
}


/* Footer
---------------------------------------------------------------------------------------------- */

#footer-wrapper {
	width: 100%;
	margin: 0 auto;
	padding: 0;
	height: auto;
	position: fixed;
	bottom: 0;
	z-index: 9999;
	background-color: #2a558c;
}

#footer {
	margin: 0 auto;
	padding: 0 16px;
	height: auto;
	min-height: 48px;
	color: white;
}

.footer-icon,
.footer-branding {
	width: 48px;
	height: 48px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 48px 48px;
}

#footer-icon-home {
	float: left;
	background-image: url('assets/svg/footer-icon-home.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 22px;
	width: 48px;
	height: 48px;
}

#footer-icon-arrow-l {
	position: absolute;
	left: 39%;
	margin-left: -24px;
	background-image: url('assets/svg/footer-icon-l-arrow.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 12px;
	width: 48px;
	height: 48px;
}

#footer-icon-v {
	position: absolute;
	left: 50%;
	margin-left: -24px;
	background-image: url('assets/images/footer-icon-valido.png');
}

#footer-icon-logo {
	position: absolute;
	left: 50%;
	margin-left: -24px;
	background-image: url('assets/svg/footer-icon-logo.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 35px;
	width: 48px;
	height: 48px;
}

#footer-icon-arrow-r {
	position: absolute;
	left: 61%;
	margin-left: -24px;
	background-image: url('assets/svg/footer-icon-r-arrow.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 12px;
	width: 48px;
	height: 48px;
}

#footer-icon-user {
	float: right;
	background-image: url('assets/svg/footer-icon-user.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 20px;
	width: 48px;
	height: 48px;
}

#footer-icon-menu {
	float: right;
	background-image: url('assets/svg/footer-icon-menu.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 18px;
	width: 48px;
	height: 48px;
}



/* Content
---------------------------------------------------------------------------------------------- */

/* Modals, Popups and Overlays */

.floating-box {
	background-color: white;
	position: absolute;
	top: 30%;
	left: 10%;
	width: 70%;
	border-radius: 5px;
	padding: 5%;
	text-align: center;
}

.floating-alert {
	background-color: #e4e6e8;
	position: absolute;
	top: 30%;
	left: 10%;
	width: 70%;
	border-radius: 5px;
	padding: 2%;
	text-align: left;
}

.floating-alert p {
	font-size: 1rem;
	color: #383838;
	text-align: left;
	padding-bottom: 0;
}

.floating-box h2.parker-id,
h2.parker-id {
	font-size: 1.25rem;
	padding: 10px 0;
	color: #697071;
}

.bottom-drawer {
	position: absolute;
	bottom: 0;
}

.align-bottom {
	display: flex;
	width: 100%;
	align-items: flex-end;
	margin: 0 auto;
	height: 100%;
	padding-left: 16px;
}

.outlinedBox {
	width: calc(100%-40px);
	border: 1px solid #cfcece;
	border-radius: 5px;
	padding: 20px;
	margin-bottom: 20px;
}

.save-favourite {
	position: absolute;
	bottom: 9%;
	right: 4.5%;
	width: 56px;
	height: 50px;
	border-radius: 10px;
	background-color: white;
	box-shadow: 0px 3px 6px rgba(0,0,0,0.22);
	background-image: url('assets/images/heart-fav-icon@2x.png');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 25px 25px;
}

.overlayBG,
.makeStyles-overlayBG-7 {
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.7);
	position: absolute;
	z-index: 9999999 !important;
	position: fixed;
}

.modal-bg-overlay {
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	top: 0;
	z-index: 9999999 !important;
}

.camera-access {
	width: 270px;
	height: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -70px;
	margin-left: -135px;
}

.panel-overlayBG {
	width: 100vw;
	height: 100vh;
	background-color: #4b4b4b;
	position: absolute;
	z-index: 9999999 !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
}


/* QR code scanning page */

.qr-code-block h4 {
	font-size: 1.25rem;
	font-weight: 800;
	color: #383838;
}

.qr-code-instructions {
	max-width: 255px;
	padding-bottom: 20px;
}

.qr-code-dummy {
	max-width: 230px;
	padding-top: 35px;
	padding-bottom: 20px;
}

.scan-box-text {
	color: white;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.5rem;
	padding-bottom: 0;
}

/* Image styles */



/* Typography
---------------------------------------------------------------------------------------------- */

p {
	font-size: 1rem;
	line-height: 1.3rem;
	color: #383838;
	padding-bottom: 15px;
}

p.no-padding {
	padding-bottom: 0;
}

p.one-sentence {
	margin-bottom: 0;
	padding-bottom: 0;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 600;
}

.centered-text {
	text-align: center;
}

.centered-text-smaller {
	text-align: center;
	font-size: 0.875rem;
	padding-bottom: 8px;
}

h2 {
	color: #1c92ad;
	font-size: 1.25rem;
	margin: 0;
	line-height: 1.2rem;
}

h1.icon-h1 {
	padding-left: 35px;
	background-repeat: no-repeat;
	background-position: left top;
	background-size: 30px 30px;
	padding-top: 7px;
	padding-bottom: 20px;
	line-height: 1.5rem;
}

h2.icon-h2 {
	padding-left: 35px;
	background-repeat: no-repeat;
	background-position: left top;
	background-size: 30px 30px;
	padding-top: 7px;
	padding-bottom: 20px;
	line-height: 1.5rem;
}

h2.no-icon-h2 {
	padding-left: 0px;
	background-repeat: no-repeat;
	background-position: left top;
	background-size: 30px 30px;
	padding-top: 7px;
	padding-bottom: 10px;
	line-height: 1.5rem;
}

h2.no-padding {
		padding-bottom: 0;
	}
	
h2.icon-h2.no-icon {
	padding-left: 0px;
}

h2.terms-of-use {
	background-image: url('assets/svg/h2-icon-terms-of-use.svg');
	background-position: left 5px;
	background-size: 18px;
}

h2.transaction-id {
	background-image: url('assets/svg/h2-icon-transaction-id.svg');
	background-position: left 7px;
	background-size: 21px;
}

h2.free-parking-earned {
	color: #5d4a7b;
	background-image: url('assets/svg/icon-hour-glass.svg');
	background-position: left 5px;
	background-size: 18px;
}

h2.end-parking-now {
	background-image: url('assets/svg/h2-icon-end-parking.svg');
	background-position: left 12px;
	background-size: 21px;
}

h2.my-vehicles {
	background-image: url('assets/svg/h2-icon-car.svg');
	background-position: left 10px;
	background-size: 24px;
}

h2.enter-vehicle-details {
	background-image: url('assets/svg/h2-icon-add-car.svg');
	background-position: left 10px;
	background-size: 27px;
}

h2.enter-charge-account,
h2.my-charge-accounts {
	background-image: url('assets/svg/h2-icon-charge-account.svg');
	background-position: left 9px;
	background-size: 24px;
}

h2.enter-credit-card {
	background-image: url('assets/svg/h2-icon-add-ccard.svg');
	background-position: left 9px;
	background-size: 29px;
}

h2.my-credit-cards {
	background-image: url('assets/svg/h2-icon-ccard.svg');
	background-position: left 9px;
	background-size: 26px;
}

h2.enter-ticket-number {
	background-image: url('assets/svg/h2-icon-enter-ticket.svg');
	background-position: left 5px;
	background-size: 18px;
}

h2.security-update {
	background-image: url('assets/svg/h2-icon-security-update.svg');
	background-position: left 5px;
	background-size: 24px;
}

h2.privacy-policy {
	background-image: url('assets/svg/h2-icon-privacy-policy.svg');
	background-position: left 5px;
	background-size: 21px;
}

h2.terms-conditions {
	background-image: url('assets/svg/h2-icon-terms-conditions.svg');
	background-position: left 5px;
	background-size: 19px;
}

h2.launch-valido {
	background-image: url('assets/svg/h2-icon-launch-valido.svg');
	background-position: left 6px;
	background-size: 23px;
}

h2.icon-h2 strong.free-parking-earned {
	font-size: 1.4rem;
	display: block;
	float: right;
	color:#5d4a7b;
}

h2.icon-h2.no-bottom-padding {
	padding-bottom: 0;
}

#header h1.carpark-providers {
	background-image: url('assets/svg/h1-icon-carparks.svg');
	background-size: 21px;
	background-position: left 17px;
}

#header h1.parking-method {
	background-image: url('assets/svg/h1-icon-parking-method.svg');
	background-size: 20px;
	background-position: left 17px;
}

h2.select-location {
	background-image: url('assets/svg/h2-icon-select-location.svg');
	background-size: 21px;
	background-position: left 4px;
}

h2.parking-offers {
	background-image: url('assets/svg/h2-icon-parking-offers-grey.svg');
	background-size: 28px;
	background-position: left 4px;
}

h2.cashier-code {
	background-image: url('assets/svg/h2-icon-cashier-code.svg');
	background-size: 24px;
	background-position: left 4px;
}

h3 {
	font-size: 1.25rem;
	color: #1c92ad;
}

h3.icon-h3 {
	padding-left: 30px;
	background-repeat: no-repeat;
	background-position: left top;
	padding-top: 5px;
	padding-bottom: 22px;
	line-height: 1.5rem;
}

h3.icon-h3.how-did-you-park {
	background-image: url('assets/svg/h3-icon-how-did-you-park.svg');
	background-size: 21px;
	background-position: left 4px;
}

h4 {
	font-size: 1.063rem;
	color: #1c92ad;
}

h4.icon-h4 {
	padding-left: 30px;
	background-repeat: no-repeat;
	background-position: left top;
	padding-top: 5px;
	padding-bottom: 20px;
	line-height: 1.5rem;
}

h4.icon-h4.important-warning {
	background-image: url('assets/svg/h4-icon-important.svg');
	background-size: 20px;
	background-position: left 4px;
	padding-bottom: 0;
}

.tc-small-text {
	font-size: 0.75rem;
}

.terms-text {
	font-size: 0.875rem;
}

.resend-link {
	text-align: right;
}

.carpark-details {
	font-size: 1.125rem;
	line-height: 1.5rem;
	border-bottom: 1px solid #d4dae0;
	padding-bottom: 7px;
	margin-bottom: 10px;
}

.car-park-pin {
	width: 12px;
	padding: 0 5px 0 0;
}

.welcome-text-block,
.not-supported-text-block {
	text-align: center;
	padding-top: 60px;
}

.delete-account-text-block {
	text-align: center;
	padding-top: 30px;
}

.success-text-block-centred {
	text-align: center;
	padding-top: 100px;
}

.searching-block {
	text-align: center;
	padding-top: 130px;
}

.searching-graphic {
	width: 88px;
	padding-top: 40px;
}

.success-text-block {
	text-align: center;
	padding-top: 0;
}

.welcome-text-block h3,
.not-supported-text-block h3 {
	font-size: 2.25rem;
	line-height: 2.7rem;
	color: #1c92ad;
	padding-bottom: 20px;
}

.delete-account-text-block h4 {
	font-size: 1.25rem;
	color: #697071;
	font-weight: 700;
	padding-bottom: 30px;
	padding-top: 20px;
}

.success-text-block h4 {
	font-size: 1.25rem;
	color: #697071;
	font-weight: 700;
	padding-bottom: 15px;
	padding-top: 20px;
}

.success-text-block-centred h4 {
	font-size: 1.25rem;
	color: #697071;
	font-weight: 700;
	padding-bottom: 30px;
	padding-top: 20px;
}

.success-text-block-centred p,
p.processing-text {
	font-size: 1.25rem;
	line-height: 1.6rem;
	color: #868484;
}

.welcome-text-block p {
	font-size: 1.25rem;
	line-height: 1.7rem;
	color: #1c92ad;
}

.success-text-block-centred .success-icon {
	margin-bottom: 15px
}

.not-supported-text-block p
.success-text-block p {
	font-size: 1.25rem;
	line-height: 1.7rem;
	color: #868484;
}

.delete-account-text-block p {
	font-size: 1.25rem;
	line-height: 1.7rem;
	color: #868484;
	padding-bottom: 7px;
}

.network-link-wrapper {
	text-align: center;
	padding-top: 15px;
}

.network-link {
	text-decoration: none;
	font-weight: 600;
	color: #5cadff;
}

.network-link::after {
	display: inline-flex;
	content: '';
	background-image: url("assets/svg/small-arrow-blue-r.svg");
	background-size: 8px 10px;
	background-repeat: no-repeat;
	background-position: right center;
	height: 10px;
	width: 17px;
	margin-right: 5px;
}

#main.congrats-header {
	margin-top: 25px;
}

.congrats-text-block {
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
}

.congrats-text-block h3 {
	font-size: 1.5rem;
	font-weight: 800;
	color: #1c92ad;
	padding-bottom: 7px;
}

.congrats-text-block p {
	font-size: 1.125rem;
	line-height: 1.6rem;
	color: #383838;
}

.congrats-text-block p strong {
	color: #1c92ad;
}

.congrats-text-block p a {
	color: #5cadff;
	text-decoration: none;
	font-weight: 700;
}

.delete-acc-icon {
	max-height: 115px;
	width: auto;
}

.delete-alert-heading {
	background-image: url("assets/svg/warning-icon-red.svg");
	background-size: 20px 17px;
	background-repeat: no-repeat;
	background-position: left top;
	padding-left: 23px;
	font-size: 1rem;
	font-weight: 700;
	color: #ec6564;
	padding-bottom: 10px;
}

.delete-alert-text {
	font-size: 1rem;
	color: #868484;
	padding-bottom: 7px;
}


/* Buttons
---------------------------------------------------------------------------------------------- */

.buttonWrapperSticky {
	position: fixed;
	bottom: 45px;
	left: 0;
	padding-top: 10px;
	padding-bottom: 20px;
	padding-top: 20px;
	background-color: white;
	z-index: 99999;
}

.panelFooterSticky {
	position: fixed;
	bottom: 40px;
	background-color: white;
	max-width: 93%; /* THIS WORKS BUT IS A BIT HACKY */
}

button {
	width: 100%;
	float: left;
	background-color: white;
	color: #9e9d9d;
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
	border-radius: 6px;
	border: 2px solid;
	border-color: #bfcad5;
}

button.facebook-login-button {
	background-image: url('assets/images/facebook-login-icon.png');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 105px 25px;
	background-color: #4267B2;
	border-color: #4267B2;
	height: 50px;
}

button.google-login-button {
	background-image: url('assets/images/google-login-icon.png');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 105px 25px;
	border-color: #E5E5E5;
	height: 50px;
}


/* Custom Flow Styles 
---------------------------------------------------------------------------------------------- */

/* Main landing page styles */

.slideWrapper {

}

.slider-text {
	font-weight: 700;
	padding-top: 2px;
	padding-bottom: 12px;
	color: #1c92ad;
}

/* Signin to your account styles */

p.bordered-text {
	text-align: center;
	padding: 0;
	margin-bottom: 10px;
	background-image: url('assets/images/bordered-text.png');
	background-position: left center;
	background-repeat: repeat-x;
}

.bordered-text-mask {
	background-color: white;
	padding: 0 20px;
}

.signup-section-wrapper {
	text-align: center;
}

.signup-section-wrapper p a {
	text-decoration: none;
	font-weight: 700;
}

p.forgot-password {
	font-size: 0.875rem;
	text-align: center;
	padding-top: 10px;
}

p.forgot-password-right {
	text-align: right;
}

p.forgot-password a:link,
p.forgot-password-right a:link {
	text-decoration: none;
}

/* Vendor offers styles */

.vendor-logo,
.car-park-img {
	float: left;
	width: 40px;
	height: 40px;
	padding-right: 10px;
	padding-bottom: 18px;
}

.vendor-image {
	padding-bottom: 10px;
}

h2.vendor-title {
	font-size: 1.25rem;
	line-height: 1.5rem;
}

h4.vendor-location {
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: 300;
	color: #383838;
}

p.vendor-description {
	font-size: 1rem;
	line-height: 1.3rem;
	color: #868484;
}

ul.ul-parking-offers {
	list-style: none;
	font-size: 1rem;
	border-top: 1px solid #e3e3e3;
	margin-top: 0px;
	color: #383838;
	margin-bottom: 20px;
}

ul.ul-parking-offers li {
	padding: 7px 0;
	border-bottom: 1px solid #e3e3e3;
	color: #383838;
}

.offer-close-button {
	display: block;
	float: right;
	margin-right: 0px;
	margin-top: 5px;
}

.offer-close-icon {
	max-width: 14px;
}

/* Merchant offer styles - eg: Myer / JB Hifi etc */
/* My car parks styles - similar to merchant offer listings */

.merchant-offer-block,
.my-car-parks-block,
.my-entries-block {
	background-color: #f2f2f2;
	padding: 0 !important;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	border-bottom: 1px solid #ffffff;
}

.merchant-offer-block {
	
}

.my-entries-block {
	min-height: 60px;
	display: flex;
	align-items: center;
}

.my-entries-block.selected {
	border: 2px solid #1c92ad;
	background-image: url('assets/svg/tick-icon.svg');
	background-position: 95% center;
	background-repeat: no-repeat;
}

.merchant-offer-block img.vendor-logo,
.my-car-parks-block img.car-park-img {
	width: 80px;
	height: 80px;
	padding-right: 15px;
	padding-bottom: 0;
}

.merchant-offer-block h4 {
	display: block;
	float: left;
	font-size: 1.25rem;
	color: #383838;
}

.my-car-parks-block h4 {
	display: block;
	float: left;
	font-weight: 700;
	font-size: 1.125rem;
	color: #697071;
	padding-top: 5px;
}

.merchant-location {
	display: block;
	float: left;
}

.my-car-parks-block .merchant-location p {
	color: #697071;
	font-size: 1rem;
}

.merchant-location-link {
	float: left;
	display: block;
	margin-right: 7px;
}

.merchant-location-pin {
	width: 12px;
	height: 16px;
}

.my-entries-block h4 {
	display: block;
	float: left;
	font-weight: 400;
	font-size: 1.2rem;
	color: #383838;
	padding-left: 16px;
}

.merchant-offer-block h4 a,
.my-car-parks-block h4 a {
	color: #383838;
	text-decoration: underline;
}

.merchant-offer-block h4.offer-time {
	display: block;
	float: right;
	font-size: 1.125rem;
	text-align: right;
	padding-right: 15px;
	font-weight: 700;
}

.merchant-offer-block .merchant-title,
.my-car-parks-block .car-park-title {
	float: left;
	width: calc(100% - 111px);
	padding-top: 10px;
	padding-bottom: 5px;
}

.merchant-offer-block .merchant-location {
	float: left;
	width: calc(100% - 111px);
}

.delete-my-entry,
.edit-my-entry {
	display: block;
	float: right;
	width: 20px;
	height: 20px;
}

.delete-my-park {
	display: block;
	float: right;
	width: 20px;
	height: 20px;
	margin-top: 10px;
}

.delete-my-entry,
.edit-my-entry {
	margin-right: 25px;
}

.delete-icon-sml,
.edit-icon-sml {
	width: 20px;
	height: 20px;
}

.vehicle-badge-icon {
	display: block;
	float: left;
	width: 40px;
	height: 40px;
	margin-left: 10px;
}

/* Entry Method styles */

h2.parking-selection {
	color: #383838;
	line-height: 22px;
	padding-bottom: 20px;
}

.parking-location-pin {
	width: 16px;
	height: 22px;
	display: inline-block;
	background-image: url('assets/svg/carpark-location-pin.svg');
	background-size: 16px;
	background-position: center;
}

.edit-parking-selection {
	width: 16px;
	height: 16px;
	display: block;
	background-image: url('assets/svg/icon-edit-selection.svg');
	background-size: 16px;
	background-position: center;
	float: right;
}

.my-entries-block h4.entry-method {
	font-size: 1.25rem;
	color: #383838;
	width: 100%;
	text-indent: 35px;
}

.my-entries-block h4.entry-method.took-ticket {
	background-image: url('assets/svg/entry-icon-ticket.svg');
	background-size: 22px;
	background-position: 16px center;
	background-repeat: no-repeat;
}

.my-entries-block h4.entry-method.licence-plate {
	background-image: url('assets/svg/entry-icon-licence-plate.svg');
	background-size: 24px;
	background-position: 16px center;
	background-repeat: no-repeat;
}

.my-entries-block h4.entry-method.used-ccard {
	background-image: url('assets/svg/entry-icon-credit-card.svg');
	background-size: 26px;
	background-position: 16px center;
	background-repeat: no-repeat;
}

.my-entries-block h4.entry-method.charge-account {
	background-image: url('assets/svg/entry-icon-charge-account.svg');
	background-size: 24px;
	background-position: 16px center;
	background-repeat: no-repeat;
}

.my-entries-block h4.entry-method a {
	background-image: url('assets/svg/small-arrow-blue-r.svg');
	background-size: 8px;
	background-repeat: no-repeat;
	background-position: 95% center;
	text-decoration: none;
	color: #383838;
	float: left;
	width: calc(100% - 32px);
}

.question-icon {
	width: 28px;
	height: 28px;
	display: block;
	background-image: url('assets/svg/icon-question.svg');
	background-size: 28px;
	background-position: center;
	position: absolute;
	right: 20%;
}

.find-charge-id {
	font-size: 1rem;
	text-align: right;
	text-decoration: none;
	display: block;
	float: right;
	padding-top: 5px;
}

.ticket-scan-box {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px;
	background-color: #eff2f4;
	height: calc(100vw - 155px);
}

.ticket-scan-box button {
	max-height: 48px;
}

.scanning-graphic {
	height: 100%;
	width: auto;
}

.car-photo-box {
	display: flex;
	justify-content: center;
	align-items: center;
}

.your-car {
	max-height: 400px;
	max-width: 100%;
	width: auto;
}

h2.parking-selection.no-padding {
	padding-bottom: 10px;
}

h4.date-selected {
	background-image: url('assets/svg/h3-icon-calendar.svg');
	background-size: 16px;
	background-position: left center;
	background-repeat: no-repeat;
	font-size: 1rem;
	color: #383838;
	font-weight: 400;
	padding-left: 24px;
}


/* Transaction ID section styles */

h1.transaction-id {
	font-size: 6.25rem;
	font-weight: 800;
	color: white;
}

.transaction-small-text {
	color: white;
	font-size: 0.875rem;
	padding-bottom: 0;
	padding-top: 40px;
}

.transaction-message {
	font-size: 1.25rem;
	line-height: 1.5rem;
	text-align: center;
}

.success-icon {
	max-width: 80px;
	height: auto;
}

.processing-graphic {
	max-width: 80px;
	height: auto;
}

.parking-has-stopped {
	font-size: 1.25rem;
	line-height: 1.7rem;
	color: #868484;
}

/* My Activity section styles */

h3.date-range {
	background-image: url('assets/svg/h3-icon-calendar.svg');
	background-size: 16px;
	background-position: left 7px;
	font-size: 1rem;
	color: #697071;
}

.date-range-wrapper {
	padding-left: 16px;
	padding-right: 16px;
}

.activity-entry-block {
	background-color: #517EAD;
	padding-top: 20px;
	padding-left: 16px;
	padding-right: 16px;
}

.activity-entry-block h4,
.activity-entry-block p,
.activity-entry-block a {
	color: white;
}

.activity-entry-block a {
	text-decoration: none;
}

.activity-title h4.parking-location {
	background-image: url('assets/svg/h2-icon-meter-white.svg');
	background-size: 14px;
	background-repeat: no-repeat;
	background-position: left 3px;
	font-size: 1.25rem;
	font-weight: 700;
	padding: 0 0 12px 20px;
}

h4.parking-location .location-id {
	font-weight: 400;
}

.activity-date-range {
	background-image: url('assets/svg/icon-calendar-white.svg');
	background-size: 16px;
	background-repeat: no-repeat;
	background-position: left 2px;
	padding: 0 0 7px 25px;
}

.activity-chosen-car {
	background-image: url('assets/svg/icon-car-white.svg');
	background-size: 18px;
	background-repeat: no-repeat;
	background-position: left 2px;
	padding: 0 0 12px 25px;
}

.activity-parking-started {
	background-image: url('assets/svg/icon-info-white.svg');
	background-size: 16px;
	background-repeat: no-repeat;
	background-position: left 2px;
	padding: 0 0 7px 25px;
	float: right;
}

.activity-actions {
	background-color: #EFF2F4;
	padding: 12px 16px 10px 16px;
	display: flex;
	justify-content: space-between;
	border-bottom: solid 12px #517EAD;
}

.activity-actions .activity-end {
	background-image: url('assets/svg/icon-stop-parking-red.svg');
	background-size: 25px;
	background-repeat: no-repeat;
	background-position: left 0px;
	padding: 0 0 5px 35px;
	font-size: 1.063rem;
	color: #FF2C2C;
	font-weight: bold;
	text-decoration: none;
}

.activity-actions .activity-extend {
	background-image: url('assets/svg/icon-extend-parking-green.svg');
	background-size: 20px;
	background-repeat: no-repeat;
	background-position: left 0px;
	padding: 0 0 5px 27px;
	font-size: 1.063rem;
	color: #5FD12B;
	font-weight: bold;
	text-decoration: none;
}

.activity-actions .activity-view-ticket {
	background-image: url('assets/svg/icon-view-ticket-blue.svg');
	background-size: 22px;
	background-repeat: no-repeat;
	background-position: left -2px;
	padding: 0 0 5px 32px;
	font-size: 1.063rem;
	color: #5CADFF;
	font-weight: bold;
	text-decoration: none;
}

.view-location {
	display: block;
	float: right;
	width: 13px;
	height: 16px;
	background-image: url('assets/svg/icon-arrow-return.svg');
	background-size: 13px;
	background-repeat: no-repeat;
	background-position: center;
	margin-top: 5px;
}

.activity-list-items {
	padding-top: 0px;
	padding-left: 16px;
	padding-right: 16px;
}

ul.ul-activity-items {
	list-style: none;
}

ul.ul-activity-items li {
	border-bottom: 1px solid #aaa9a9;
	padding: 9px 0 9px 30px;
	background-repeat: no-repeat;
	background-position: left center;
	color: #868484;
}

ul.ul-activity-items li.no-icon {
	padding: 9px 0 9px 0px;
}

ul.ul-activity-items li.li-activity-subtotal,
ul.ul-activity-items li.li-activity-subtotal .activity-item-price {
	color: #383838;
}

ul.ul-activity-items li.li-activity-date {
	background-image: url('assets/svg/icon-activity-date.svg');
	background-size: 16px;
}

ul.ul-activity-items li.li-activity-product {
	background-image: url('assets/svg/icon-activity-parking.svg');
	background-size: 16px;
}

ul.ul-activity-items li.li-activity-reminder {
	background-image: url('assets/svg/icon-activity-reminder.svg');
	background-size: 16px;
}

ul.ul-activity-items li.li-activity-charge {
	background-image: url('assets/svg/icon-activity-charge.svg');
	background-size: 16px;
}

ul.ul-activity-items li.li-activity-discount {
	background-image: url('assets/svg/icon-activity-discount.svg');
	background-size: 18px;
}

ul.ul-activity-items li.li-activity-ccard {
	background-image: url('assets/svg/icon-activity-ccard.svg');
	background-size: 18px;
}

ul.ul-activity-items li a {
	text-decoration: none;
}

ul.ul-activity-items li .activity-item-qty {
	position: absolute;
	right: 25%;
}

ul.ul-activity-items li .activity-item-price,
ul.ul-activity-items li .ticket-number {
	float: right;
	color: #868484;
}


/* Profiles section styles */

.camera-section,
.profile-section,
.delete-section {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.camera-section {
	justify-content: flex-end;
}

.delete-section {
	justify-content: left;
}

.profile-camera-icon {
	max-width: 20px;
}

.profile-trash-icon {
	max-width: 15px;
}

.profile-avatar {
	max-width: 100px;
	margin: 0 auto;
	border-radius: 50%;
}

.align-center {
	text-align: center;
}


/* Logged in user styles */

.menu-slidein-wrapper {
	
}

.menu-close-button {
	display: block;
	float: right;
	margin-right: 20px;
}

.menu-close-icon {
	max-width: 14px;
}

.user-avatar {
	max-width: 53px;
	margin: 0 auto;
	border-radius: 50%;
	float: left;
	border: 1px solid #aaadad;
	margin-right: 15px;
}

h3.welcome-user {
	font-size: 1rem;
	font-weight: 500;
	color: white;
	padding-top: 5px;
	padding-bottom: 3px;
}

h2.user-nicename {
	font-size: 1.25rem;
	font-weight: 800;
	padding-bottom: 30px;
	color: white;
}

ul.amenities-list,
ul.open-times,
ul.daily-tariffs,
ul.user-loggedin-menu {
	list-style: none;
}

ul.amenities-list li {
	border-bottom: 1px solid #aaadad;
	padding: 6px 0 6px 40px;
	font-size: 1rem;
	background-repeat: no-repeat;
}

ul.user-loggedin-menu {
	border-top: 1px solid #aaadad;
}

ul.user-loggedin-menu li {
	border-bottom: 1px solid #aaadad;
	padding: 13px 0 13px 55px;
	font-size: 0.875rem;
	background-repeat: no-repeat;
}

ul.open-times li,
ul.daily-tariffs li {
	border-bottom: 1px solid #365F84;
	padding: 6px 0 6px 0;
	font-size: 1rem;
	background-repeat: no-repeat;
}

ul.open-times li .day-times {
	float: right;
	margin-right: 25px;
}

ul.daily-tariffs li .hourly-price {
	float: right;
	margin-right: 40%;
}

ul.amenities-list li a,
ul.user-loggedin-menu a {
	color: white;
	text-decoration: none;
}

li.amenities-motorcycle {
	background-image: url('assets/svg/amenities-icon-motorbike.svg');
	background-position: 5px center;
}

li.amenities-cctv {
	background-image: url('assets/svg/amenities-icon-cctv.svg');
	background-position: 7px center;
}

li.amenities-gated {
	background-image: url('assets/svg/amenities-icon-gated.svg');
	background-position: 5px center;
}

li.amenities-secured {
	background-image: url('assets/svg/amenities-icon-secured.svg');
	background-position: 9px center;
}

li.amenities-evc {
	background-image: url('assets/svg/amenities-icon-evc.svg');
	background-position: 7px center;
}

li.amenities-covered {
	background-image: url('assets/svg/amenities-icon-covered.svg');
	background-position: 7px center;
}

li.amenities-disabled {
	background-image: url('assets/svg/amenities-icon-disabled.svg');
	background-position: 9px center;
}

/* User Menu Icons */

li.menu-link-home {
	background-image: url('assets/svg/account-icon-home.svg');
	background-position: 12px center;
}

li.menu-link-my-parks {
	background-image: url('assets/svg/account-icon-parks.svg');
	background-position: 10px center;
}

li.menu-link-my-profile {
	background-image: url('assets/svg/account-icon-profile.svg');
	background-position: 14px center;
}

li.menu-link-my-vehicles {
	background-image: url('assets/svg/account-icon-vehicles.svg');
	background-position: 9px center;
}

li.menu-link-my-cards {
	background-image: url('assets/svg/account-icon-cards.svg');
	background-position: 10px center;
}

li.menu-link-fav-carparks {
	background-image: url('assets/svg/account-icon-favourites.svg');
	background-position: 11px center;
}

li.menu-link-security {
	background-image: url('assets/svg/account-icon-security.svg');
	background-position: 11px center;
}

li.menu-link-signout {
	background-image: url('assets/svg/account-icon-signout.svg');
	background-position: 13px center;
}

li.menu-link-forget-me {
	background-image: url('assets/svg/account-icon-trash.svg');
	background-position: 13px center;
}

li.menu-link-my-activity {
	background-image: url('assets/svg/account-icon-activity.svg');
	background-position: 13px center;
}


/* Narrow mobile fixes */

@media screen and (max-width: 400px) {

	a#nuevopark-logo {
		width: 150px;
		height: 35px;
		background-size: 150px 35px;
		margin-top: 28px;
	}

	p.fix-mobile-wrap {
		font-size: 0.9rem;
		padding-bottom: 15px !important;
	}
	
	.mobile-button-override button {
		font-size: 0.9rem !important;
	}
	
	p.transaction-message {
		font-size: 1rem;
	}
	
	.smaller-text-size {
		font-size: 1rem !important;
	} 
	
	#header-wrapper.landing-page-header {
		height: 270px
	}
	
	.header-button-wrapper {
		margin-top: 45px;
	}
	
	.parking-has-stopped {
		font-size: 1rem;
		line-height: 1.5rem;
		color: #868484;
	}
	.success-icon {
		padding: 10px 0 0 0 !important;
	}
	
	.my-entries-block h4.entry-method {
		font-size: 1.1rem;
	}
	
	.success-text-block-centred {
		text-align: center;
		padding-top: 70px;
	}
	
	.success-text-block-centred p,
	p.processing-text {
		font-size: 1rem;
		line-height: 1.4rem;
	}
	
	.my-car-parks-block .merchant-location p {
		font-size: 0.8rem;
	}
	
	p.vendor-description {
		font-size: 0.9rem;
	}

}